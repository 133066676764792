var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "int-auth-wrapper" }, [
    _c(
      "div",
      { staticClass: "int-auth" },
      [_c("b-loading", { attrs: { active: true, "is-full-page": false } })],
      1
    ),
    _vm._v(
      " Authenticating connection to " +
        _vm._s(_vm.integrationData.title) +
        "... "
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }