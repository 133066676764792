<template>
  <div class="int-auth-wrapper">
    <div class="int-auth">
      <b-loading :active="true" :is-full-page="false"></b-loading>
    </div>
    Authenticating connection to {{ integrationData.title }}...
  </div>
</template>

<script>
import integrations, {
  userFileStorageIntegrations,
  userCalendarIntegrations
} from '@c/integrations.ts'
import { isEmpty } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    integration: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      linkedWorkspaceId: localStorage.getItem('integrationWorkspace'),
      nonceFromStorage: localStorage.getItem('integrationNonce'),
      broadcast: new BroadcastChannel('uman_integration_auth')
    }
  },

  computed: {
    ...mapGetters(['workspaces']),

    workspace() {
      return this.workspaces.find(w => w.uuid === this.linkedWorkspaceId)
    },

    integrationData() {
      return {
        workspace: integrations[this.integration],
        user: userFileStorageIntegrations[this.integration],
        member: userCalendarIntegrations[this.integration]
      }[this.integrationType]
    },

    code() {
      return this.$route.query.code
    },

    error() {
      return this.$route.query.error
    },

    state() {
      return this.$route.query.state
    },

    nonce() {
      return this.state ? this.state.split('%')[0] : undefined
    },

    integrationType() {
      return this.state ? this.state.split('%')[1] : undefined
    },

    isValid() {
      return (
        this.integration &&
        this.nonce &&
        this.code &&
        this.integrationData?.active &&
        !(this.integrationType === 'workspace' && isEmpty(this.workspace))
      )
    }
  },

  created() {
    if (this.error || !this.isValid || this.nonceFromStorage !== this.nonce) {
      this.onError(this.error)
    } else {
      this.onSubmit()
    }
  },

  methods: {
    ...mapActions([
      'authenticateIntegration',
      'authenticateUserIntegration',
      'authenticateWorkspaceMemberIntegration',
      'getCurrentWorkspaceMember'
    ]),

    async onSubmit() {
      try {
        if (this.integrationType === 'workspace')
          await this.authenticateIntegration({
            workspace_id: this.workspace.uuid,
            integration: this.integration,
            ...(this.integrationData.setup.usesAPIKey ||
            this.integrationData.setup.usesCookie
              ? { api_key: this.code }
              : { oauth: { code: this.code } })
          })
        else if (this.integrationType === 'user')
          await this.authenticateUserIntegration({
            integration: this.integration,
            code: this.code
          })
        else if (this.integrationType === 'member') {
          const member = await this.getCurrentWorkspaceMember({
            workspace_id: this.workspace.uuid
          })
          await this.authenticateWorkspaceMemberIntegration({
            workspace_id: this.workspace.uuid,
            member_id: member.uuid,
            integration: this.integration,
            code: this.code
          })
        }
        localStorage.removeItem('integrationNonce')
        localStorage.removeItem('integrationWorkspace')
        this.broadcast.postMessage('done')
        window.close()
      } catch (e) {
        this.onError(e)
      }
    },

    onError(e = undefined) {
      this.broadcast.postMessage(typeof e === 'object' ? 'error' : e || 'error')
      window.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.int-auth {
  height: 20vh;
  width: 100%;
  &-wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
  }
}
</style>
